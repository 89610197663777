import { allArches, allData, allDls } from '.contentlayer/generated'
import type { GetStaticProps } from 'next'
import Layout from './../layouts/index'
import { useMDXComponent } from 'next-contentlayer/hooks'
import { MDXComponents } from 'components/mdx-components/mdx-components'

export const getStaticProps: GetStaticProps = () => {
  // const arch = allArches.find(arch => arch.url === '/arch/getting-started')
  const arch = allData.find(data => data.url === '/data/lake-formation')
  // const arch = allDls.find(dl => dl.url === '/dl/vercel-bedrock-chat-image')

  return { props: { arch } }
}

const ArchHomePage = ({ arch }) => {
  const MDXContent = useMDXComponent(arch.body.code)

  return (
    <Layout frontMatter={arch?.frontMatter}>
      <MDXContent components={MDXComponents}></MDXContent>
    </Layout>
  )
}

export default ArchHomePage
